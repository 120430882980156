
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Grid, Button } from '@mui/material';
import axiosCredentialsInstance from "../Axios/axiosCredentials";
function LoginView() {
  const [formData, updateFormData] = useState(Object.freeze({
    username: "",
    password: "",
  }));
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const Navigate = useNavigate();
  const handleChange = (e: any) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };


  const handleSubmitSignIn = (e: any) => {
    e.preventDefault();
    axiosCredentialsInstance.post('/session/login', {
      username: formData.username,
      password: formData.password,
    })
    .then((res) => {
      Navigate('/')
      window.location.reload()
    })
    .catch((err:any) => {
      setUsernameError(err.response.data.error === "username" ? true : false)
      setPasswordError(err.response.data.error === "password" ? true : false)

    })

  };


  /// the problem is about https

  return (
      <div className="flex flex-col h-screen justify-between bg-gray-100">
        <div className="relative w-full md:w-128 mx-auto pb-3 mt-10 bg-white">
          <div className="flex justify-around">
            <button
              className={"w-full h-12 text-center border-black "}
              onClick={() => {
                setUsernameError(false);
                setPasswordError(false);
              }}
            >
              登入
            </button>
          </div>
            <form  noValidate className="flex h-52">
              <div className="mx-auto">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    error={usernameError}
                    variant="outlined"
                    required
                    fullWidth
                    id="username"
                    label="用戶名"
                    name="username"
                    autoComplete="username"
                    helperText={usernameError ? "帳號錯誤" : ""}
                    onChange={handleChange}
                    onBlur={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={passwordError}
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="密碼"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    helperText={
                      passwordError ? "密碼錯誤" : ""
                    }
                    onChange={handleChange}
                    onBlur={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSubmitSignIn}
              >
                登入
              </Button>
              </Grid>
              </Grid>
              </div>
            </form>
            
        </div>
      </div>
  );
}
export default LoginView;
