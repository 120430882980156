import { useState, useEffect, Fragment, useRef } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import MenuItemCard from '../components/MenuItemCard';
import { Restaurant, OrderRequest, OrderRequestItems } from '../interfaces';
import { OrderContext } from '../context/OrderContext';
import axiosCredentialsInstance from '../Axios/axiosCredentials';
import { MenuItem, Quota } from '../interfaces';
import Navbar from '../components/NavBar';
import Loading from '../components/Loading';
function AdminOrderView() {
    const [ searchParams ] = useSearchParams();
    const token = searchParams.get("token")
    const Navigate = useNavigate();
    const [ restaurant, setRestaurant ] = useState({} as Restaurant)
    const [ orderRequest, setOrderRequest ] = useState({ orderDetail: [], note: ""} as OrderRequest);
    const [ groupedMenuItems, setGroupedMenuItems ] = useState({} as { [key: string]: MenuItem[] })
    const [ loading, setLoading ] = useState(true)
    const menuOrder = ['main', 'side', 'drink']
    const [ confirm, setConfirm ] = useState(false)


    const stickyDivRef = useRef<HTMLDivElement>(null);
    const elementRef = useRef<HTMLDivElement>(null);
    const [ stickyPoint, setStickyPoint ] = useState(0);

    
    useEffect(() => {
        const element = elementRef.current;
        if(element){setStickyPoint(element!.offsetHeight + element!.offsetTop)}
    },[])

    useEffect(() => {
        const handleScroll = () => {
          const stickyDiv = stickyDivRef.current;
          const elementDiv = elementRef.current;

          if (stickyDiv && elementDiv) {
            const scrollPosition = (window.pageYOffset || document.documentElement.scrollTop) + window.innerHeight;
            if (scrollPosition >= stickyPoint) {
              stickyDiv.style.position = 'sticky';
            } else {
              stickyDiv.style.position = 'fixed';
              stickyDiv.style.top = 'auto';
            }
          }
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    

    const handleSubmit = (e: any) => {
        e.preventDefault();
        axiosCredentialsInstance.post(`/order/admin/order`, orderRequest).then(data => {
            Navigate(`/order-summary`)
            window.location.reload()
        }).catch(err => {
        })
      }

    useEffect(() => {
        async function fetchData() {
        try {
            const orderDetailResponse = await axiosCredentialsInstance.get(`/order/admin/order`);
            console.log(orderDetailResponse)
            const orderDetail = orderDetailResponse.data;            
            
            const orderItems = orderDetail.flatMap((order: { orderItems: any[] }) => {
                return order.orderItems.map((item: { menuItemId: string, quantity: number, name: string }) => {
                    return {
                        menuItemId: item.menuItemId,
                        quantity: item.quantity,
                        name: item.name,
                    }
                })
            })

            setOrderRequest({
                orderDetail: orderItems,
                note: orderDetail.note,
            });


            const restData = await axiosCredentialsInstance.get('/restaurant/activated/menu');
            const restaurantData = restData.data;
            
            const menuItems = restaurantData.reduce((group: { [key: string]: MenuItem[] }, menuItem: MenuItem) => {
            const { type } = menuItem;
            group[type] = group[type] ?? [];
            group[type].push(menuItem);
            return group;
            }, { side: [], main: [], drink: [] } as { [key: string]: MenuItem[] });

            setGroupedMenuItems(menuItems);
            setRestaurant(restaurantData);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
        }
        
        fetchData();
    }, []);

    return (
        <>
        <Navbar />
        <OrderContext.Provider value={{orderRequest, setOrderRequest}}>
        {loading ? <Loading /> : ( !confirm &&
            <div ref={elementRef} className='w-full flex flex-col justify-center my-6'>
                <div className='mx-auto max-w-screen-lg w-full md:w-1/2 px-4'>
                    {menuOrder.map((key: string) => {
                        const groupedMenu = groupedMenuItems[key].map((menuItem: MenuItem) => {
                        return <MenuItemCard menuItem={menuItem} key={menuItem._id} language="mandarin"/>;
                        });
                        return (
                        <Fragment key={key}>
                            <h1 className="text-3xl border-b-2 border-black">{key}</h1>
                            {groupedMenu}
                        </Fragment>
                        );
                    })}

                </div>
                <div ref={stickyDivRef} id="stickyDiv" className="flex items-end justify-center fixed bottom-0 w-full">
                    <button className="bg-blue-500 w-full hover:bg-blue-700 text-white font-bold py-2 px-4" onClick={() => {
                    setConfirm(true);
                    }}>
                    檢查購物車
                    </button>
                </div>
            </div>
            )
        }
        {!confirm ? <></>: (
            <>
            <div className='flex flex-col p-5'>
                <div className="flex justify-center items-center ">
                    <table className="table-auto w-128">
                    <thead>
                        <tr>
                        <th className="px-4 py-2 text-left">名稱</th>
                        <th className="px-4 py-2 text-left">數量</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderRequest && orderRequest.orderDetail.map((item, index) => {
                        return (
                            <tr key={index}>
                            <td className="border px-4 py-2">{item.name}</td>
                            <td className="border px-4 py-2">{item.quantity}</td>
                            </tr>
                        );
                        })}
                    </tbody>
                    </table>
                </div>
                <div className="flex justify-around w-full text-2xl my-5">
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-1/2" onClick={handleSubmit}>
                        確認送出訂單
                    </button>
                </div>
                <div className="flex justify-around w-full text-2xl my-5">
                        <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-1/2" onClick={()=>{
                        setConfirm(false)
                    }}>修改訂單</button>
                </div>
            </div>
        </>
        )}
        </OrderContext.Provider>
        </>
  );
}

export default AdminOrderView;

