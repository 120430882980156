import { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Room } from '../interfaces';
import RoomCard from '../components/roomCard';
import Navbar from '../components/NavBar';
import axiosCredentialsInstance from '../Axios/axiosCredentials';
import '../App.css';


function DashBoard() {
    const Navigate = useNavigate()
    const [rooms, setRooms] = useState([] as Room[])
    const [clickedFloors, setClickedFloors] = useState<string[]>([]);

    useEffect(()=>{
        axiosCredentialsInstance.get('/room').then(data => {
            setRooms(data.data)
        })
    },[])
    return (
        <>
        <Navbar />

        <div className='w-full flex flex-col justify-center mt-10'>
            <div className='mx-auto'>

                {rooms && rooms.length > 0 && (
                <>
                    {rooms.map((room: Room, index: number) => {
                    const floor = room.roomNumber.substring(0, 1);
                    const isFloorClicked = clickedFloors.includes(floor);

                    if (index === 0 || floor !== rooms[index - 1].roomNumber.substring(0, 1)) {
                        return (
                        <Fragment key={floor}>
                            <h1
                            className="text-2xl border-b-4"
                            onClick={() => {
                                if (isFloorClicked) {
                                setClickedFloors(clickedFloors.filter((f) => f !== floor));
                                } else {
                                setClickedFloors([...clickedFloors, floor]);
                                }
                            }}
                            >
                            {`${floor}樓`}
                            </h1>
                            {isFloorClicked &&
                            rooms
                                .filter((r) => r.roomNumber.substring(0, 1) === floor)
                                .map((filteredRoom: Room) => (
                                <RoomCard room={filteredRoom} key={filteredRoom._id} />
                                ))}
                        </Fragment>
                        );
                    } else {
                        return null;
                    }
                    })}
                </>
                )}

            <div className='w-96 md:w-128 lg:w-220 m-3'>
                <div className='flex justify-between rounded-md'>
                    <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full' onClick={()=>{
                        Navigate(`/room/add`)
                    }}>新增房間</button>
                </div>
            </div>
            </div>
        </div>
        </>
  );
}

export default DashBoard;
