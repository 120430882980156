export const initialRoomFormData = () =>{
    return Object.freeze({
        roomNumber: '',
        roomType: "",
        quantity: {
            main: 0,
            side: 0,
            drink: 0
        },
        note: ""
    })
}

export const initialRestaurantFormData = () =>{
    return Object.freeze({
        name: '',
        address: "",
        phone: "",
        activate: false,
        note: ""
    })
}

export const initialMenuItemFormData = () =>{
    return Object.freeze({
        name: '',
        englishName: "",
        japaneseName: "",
        price: 0,
        type: "",
        description: "",
        photoUrl: "",
        note: ""
    })
}