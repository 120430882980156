import { useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Order } from '../interfaces';
import axiosInstance from '../Axios/axios';
function RoomOrderInfoView() {
    const [ searchParams ] = useSearchParams();
    const [ orderDetail, setOrderDetail ] = useState({} as Order)
    const [ loading, setLoading ] = useState(true)
    const [ roomNumber, setRoomNumber ] = useState()
    const { roomId } = useParams();
    const token = searchParams.get("token")
    const language = searchParams.get("language")
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    
    useEffect(()=>{
        axiosInstance.get(`/order/${roomId}`).then(data =>{
            setOrderDetail(data.data)
            setLoading(false)
        })

        axiosInstance.get(`/room/${roomId}`).then(data =>{
            setRoomNumber(data.data.roomNumber)
        })

    },[])

    return (
        <div className='flex flex-col p-5'>
            <h1 className='flex justify-center text-3xl my-10'>Hello {roomNumber}</h1>
            <p className='flex justify-center text-2xl text-center'>
                {language === 'mandarin' ? '我們已經為您送出了餐點！' : language === 'english' ? 'We have already dispatched your meal!' : 'すでにお食事を発送しました！'}
            </p>
            <p className='flex justify-center text-2xl text-center'>
                {language === 'mandarin' ? '如果您對餐點有任何問題' : language === 'english' ? 'If you have any concerns regarding your meal' : 'お食事に関するご質問がある場合は'}
            </p>
            <p className='flex justify-center text-2xl text-center'>
                {language === 'mandarin' ? '請撥打9號或04-23265463聯絡我們' : language === 'english' ? 'please feel free to contact us at extension 9 or call 04-23265463' : '内線9または04-23265463までお気軽にお問い合わせください'}
            </p>
            <div className="flex justify-center items-center ">
            <table className="table-auto w-128">
            <thead>
                <tr>
                <th className="px-4 py-2 text-left">
                    {language === 'mandarin' ? '品項' : language === 'english' ? 'Item' : 'アイテム'}    
                </th>
                <th className="px-4 py-2 text-left">
                    {language === 'mandarin' ? '數量' : language === 'english' ? 'Quantity' : '数量'}
                </th>
                </tr>
            </thead>
            <tbody>
                {!loading && orderDetail && orderDetail.orderItems.map((item, index) => {
                return (
                    <tr key={index}>
                    <td className="border px-4 py-2">
                        {language === 'mandarin' ? item.name : language === 'english' ? item.englishName : item.japaneseName}
                    </td>
                    <td className="border px-4 py-2">{item.quantity}</td>
                    </tr>
                );
                })}
            </tbody>
            </table>
        </div>
      </div>

  );
}

export default RoomOrderInfoView;

