import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuItem } from '../interfaces';
import { Transition }  from '@headlessui/react';
import { Switch } from '@mui/material';
import '../App.css';
import axiosCredentialsInstance from '../Axios/axiosCredentials';


function RestaurantCard(props: any) {
    const Navigate = useNavigate();
    const [showDropDown, setShowDropDown] = useState(false)
    const [showDeleteOrderPopUp, setShowDeleteOrderPopUp] = useState(false)
    const [menuItems, setMenuItems] = useState([] as MenuItem[])

    const handleChange = (e: any) => {
        axiosCredentialsInstance.post(`/restaurant/${props.restaurant._id}/activate`, {activate: e.target.checked})
        window.location.reload()
    }
    const typeOrder: Record<string, number> = {
        main: 1,
        side: 2,
        drink: 3,
    };

    return (
        <div className='w-96 md:w-128 lg:w-220 m-3'>
        <div className='bg-orange-200 hover:bg-orange-300 p-3 flex justify-between rounded-md' onClick={()=>{
            axiosCredentialsInstance.get(`/restaurant/${props.restaurant._id}`).then(menu => {
                setMenuItems(menu.data.menuItems.sort((a: any, b: any) => {
                    const orderA = typeOrder[a.type] || 0;
                    const orderB = typeOrder[b.type] || 0;
                  
                    return orderA - orderB;
                  }))
            })
            setShowDropDown(!showDropDown)
        }}>
        <div className='flex items-center min-h-full'>
                <p className='mx-2' onClick={()=>{
                    console.log(menuItems)
                }}>
                    店名:{props.restaurant.name}
                </p>
            </div>
            <div className='flex items-center h-full'>
                <p className='mx-2'>
                    地址:{props.restaurant.address}
                </p>
                <p className='mx-2' onClick={(e) => e.stopPropagation()}>
                <Switch onChange={handleChange} 
                defaultChecked={props.restaurant.activate ? true : false} 
                />
                </p>
            </div>
        </div>

        <div className={`overflow-hidden transition-all duration-500 ${showDropDown ? 'h-auto' : 'h-0 max-h-0'}`}>
            <div className='p-3 border-2 rounded-md flex flex-wrap'>
            <div className="flex flex-col w-full md:w-4/5 lg:w-2/3 mx-auto">
                    <h1 className='text-xl font-bold'>菜單</h1>
                    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 mx-auto">
                        <div className="inline-block min-w-full py-2 sm:px-4 lg:px-6">
                        <div className="overflow-hidden">
                            <table className="w-80 md:w-96 lg:w-128 min-w-full text-left text-sm font-light">
                            <thead className="border-b font-medium dark:border-neutral-500">
                                <tr>
                                <th scope="col" className="px-4 py-3">名稱</th>
                                <th scope="col" className="px-4 py-3">單價</th>
                                <th scope="col" className="px-4 py-3">照片</th>
                                <th scope="col" className="px-4 py-3">種類</th>

                                <th scope="col" className="px-4 py-3"></th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                menuItems && menuItems.length > 0 ? menuItems.map((menuItem: MenuItem, index: number) => {
                                    return (
                                        <tr className="border-b dark:border-neutral-500" key={index}>
                                        <td className="whitespace-nowrap px-4 py-3 font-medium flex flex-col">
                                            <div className='w-44 overflow-hidden whitespace-normal'>{menuItem.name}</div>
                                            <div className='w-44 overflow-hidden whitespace-normal'>{menuItem.englishName}</div>
                                            <div className='w-44 overflow-hidden whitespace-normal'>{menuItem.japaneseName}</div>
                                        </td>

                                        <td className="whitespace-nowrap px-4 py-3">{menuItem.price}</td>
                                        <td className="whitespace-nowrap px-4 py-3"><img src={menuItem.photoUrl ? menuItem.photoUrl : "https://res.cloudinary.com/dtziypksf/image/upload/v1685063616/no_image_muovmw.webp"} alt="" className='w-20 h-20'/></td>
                                        <td className="whitespace-nowrap px-4 py-3">{menuItem.type}</td>
                                        <td className="whitespace-nowrap px-4 py-3"><button className='bg-green-200 hover:bg-green-300 p-3 m-2 rounded-md' onClick={()=>{
                                            Navigate(`/restaurant/${menuItem._id}/menu-item/edit`)
                                        }}>修改</button></td>
                                       </tr>
                                    )
                            }) : ''}

                            </tbody>
                            </table>
                            
                        </div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col w-full lg:w-1/3 py-5 lg:pl-2 mx-auto'>
                    <button className='bg-green-200 hover:bg-green-300 p-3 m-2 rounded-md' onClick={()=>{
                        Navigate(`/restaurant/${props.restaurant._id}/menu-item/add`)
                    }}>
                            新增餐廳菜單
                    </button>
                    
                    <button className='bg-green-200 hover:bg-green-300 p-3 m-2 rounded-md' onClick={()=>{
                        Navigate(`/restaurant/${props.restaurant._id}/edit`)
                    }}>
                            修改餐廳資訊
                    </button>
                    <Transition
                        show={showDeleteOrderPopUp}
                        enter="transition-opacity duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div
                        className="fixed top-0 left-0 w-full h-full z-50 flex items-center justify-center"
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                        >
                        <div className="bg-white rounded-lg p-8">
                                <div className="flex justify-around w-full">
                                    <button
                                    className="bg-red-300 hover:bg-red-400 text-white font-bold py-2 px-4 m-2 rounded-md flex justify-center text-3xl align-middle w-full"
                                    onClick={()=>{
                                        axiosCredentialsInstance.delete(`/restaurant/${props.restaurant._id}`).then(res => {
                                            window.location.reload()
                                        })
                                        setShowDeleteOrderPopUp(false)
                                    }}
                                    >
                                    刪除
                                    </button>
                                    <button className='bg-green-300 hover:bg-green-400 text-white font-bold py-2 px-4 m-2 rounded-md flex justify-center text-3xl align-middle w-full'
                                    onClick={()=>{
                                        setShowDeleteOrderPopUp(false)
                                    }}>
                                    取消
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition>
                    {
                        <button className='bg-red-200 hover:bg-red-300 p-3 m-2 rounded-md' onClick={()=>{setShowDeleteOrderPopUp(true)}}>
                                刪除餐廳
                        </button>
                    }
                </div>
                </div>
            </div>
        </div>
  );
}

export default RestaurantCard;
