import React from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  message: string;
}

const ErrorView: React.FC<Props> = ({ message }) => {
  const Navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <svg className="w-12 h-12 text-red-500 mb-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
      </svg>
      <p className="text-xl text-center text-red-500 font-bold">{message}</p>
      <p className='text-xl' onClick={()=>{
        Navigate('/')
      }}>點此回到主頁</p>
    </div>
  );
};

export default ErrorView;
