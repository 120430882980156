import { useState, useEffect, useRef, Fragment } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import MenuItemCard from '../components/MenuItemCard';
import { Restaurant, OrderRequest, OrderRequestItems } from '../interfaces';
import { OrderContext } from '../context/OrderContext';

import axiosInstance from '../Axios/axios';
import { MenuItem, Quota } from '../interfaces';
import Loading from '../components/Loading';

function RoomOrderView() {
    const [ searchParams ] = useSearchParams();
    const token = searchParams.get("token")
    const Navigate = useNavigate();
    const { roomId } = useParams();
    const [ restaurant, setRestaurant ] = useState({} as Restaurant)
    const [ orderRequest, setOrderRequest ] = useState({ orderDetail: [], note: ""} as OrderRequest);
    const [ quota, setQuota ] = useState({} as Quota)
    const [ groupedMenuItems, setGroupedMenuItems ] = useState({} as { [key: string]: MenuItem[] })
    const [ loading, setLoading ] = useState(true)
    const [ confirm, setConfirm ] = useState(false)
    const [ language, setLanguage ] = useState('mandarin')
    const menuOrder = ['main', 'side', 'drink']
    const menuOrderTranslations: { [key: string]: any } = {
        main: {mandarin:'主餐', english:'Main', japanese:'メイン'},
        side: {mandarin:'配菜', english:'Side', japanese:'サイド'},
        drink: {mandarin:'飲料', english:'Drink', japanese:'ドリンク'},
      };

      const stickyDivRef = useRef<HTMLDivElement>(null);
      const elementRef = useRef<HTMLDivElement>(null);
      const [ stickyPoint, setStickyPoint ] = useState(0);
  
      
      useEffect(() => {
          const element = elementRef.current;
          if(element){setStickyPoint(element!.offsetHeight + element!.offsetTop)}
      },[])
  
      useEffect(() => {
          const handleScroll = () => {
            const stickyDiv = stickyDivRef.current;
            const elementDiv = elementRef.current;
  
            if (stickyDiv && elementDiv) {
              const scrollPosition = (window.pageYOffset || document.documentElement.scrollTop) + window.innerHeight;
              if (scrollPosition >= stickyPoint) {
                stickyDiv.style.position = 'sticky';
              } else {
                stickyDiv.style.position = 'fixed';
                stickyDiv.style.top = 'auto';
              }
            }
          };
      
          window.addEventListener('scroll', handleScroll);
          return () => {
            window.removeEventListener('scroll', handleScroll);
          };
        }, []);
  

  
    const handleSubmit = (e: any) => {
        e.preventDefault();
        axiosInstance.post(`/order/${roomId}`, orderRequest).then(data => {
            Navigate(`/room-order-info/${roomId}?token=${token}&language=${language}`)
            window.location.reload()
        }).catch(err => {
        })
      }
      
      useEffect(() => {
        async function fetchData() {
          try {
            const dataResponse = await axiosInstance.get(`/room/${roomId}`);
            const data = dataResponse.data;

            const orderDetailResponse = await axiosInstance.get(`/order/${roomId}`);
            const orderDetail = orderDetailResponse.data;

            if(orderDetail !== null){
                Navigate(`/room-order-info/${roomId}?token=${token}`)
            }

            setQuota(data.quantity);
      
            if (orderDetail != null) {
              orderDetail.orderItems.forEach((orderItem:any) => {
                const type = orderItem.type as keyof Quota;
                setQuota((quota: Quota) => ({
                  ...quota,
                  [type]: quota[type] - orderItem.quantity,
                }));
              });


            const orderItems = orderDetail.orderItems.map(({ menuItemId, quantity, name, englishName, japaneseName }: OrderRequestItems) => ({
                menuItemId,
                quantity,
                name,
                englishName,
                japaneseName,
            }));
            
            setOrderRequest({
                orderDetail: orderItems,
                note: orderDetail.note,
            });
            }
      
            const restData = await axiosInstance.get('/restaurant/activated/menu');
            const restaurantData = restData.data;
      
            const menuItems = restaurantData.reduce(
              (group: { [key: string]: MenuItem[] }, menuItem: MenuItem) => {
                const { type } = menuItem;
                group[type] = group[type] ?? [];
                group[type].push(menuItem);
                return group;
              },
              { side: [], main: [], drink: [] } as { [key: string]: MenuItem[] }
            );
      
            setGroupedMenuItems(menuItems);
            setRestaurant(restaurantData);
            setLoading(false);
          } catch (error) {
          }
        }
      
        fetchData();
      }, []);

    return (
        <OrderContext.Provider value={{orderRequest, setOrderRequest, quota, setQuota}}>
            {loading ? <Loading/> : ( !confirm &&
            <div ref={elementRef} className='w-full flex flex-col justify-center my-6'>
                <div className="flex justify-center items-center w-4/5 lg:w-1/2 mx-auto my-10">
                    <div className={`w-1/3 text-center font-bold rounded ${language === 'mandarin' ? 'bg-gray-200' : 'bg-gray-100'} border-y-2`} onClick={() => { 
                        setLanguage('mandarin')}}>
                        中文
                    </div>
                    <div className={`w-1/3 text-center font-bold rounded ${language === 'english' ? 'bg-gray-200' : 'bg-gray-100'} border-y-2`} onClick={() => setLanguage('english')}>
                        English
                    </div>
                    <div className={`w-1/3 text-center font-bold rounded ${language === 'japanese' ? 'bg-gray-200' : 'bg-gray-100'} border-y-2`}onClick={() => setLanguage('japanese')}>
                        日本語
                    </div>
                </div>
                <div className="flex justify-center items-center w-4/5 lg:w-1/2 mx-auto my-10">
                    <h1 className='text-2xl text-center w-full'>
                        {language === 'mandarin' ? '餐點數量' : language === 'english' ? 'Meal quantity' : '食事の数'}
                    </h1>
                </div>
                <div className="flex justify-center items-center w-4/5 lg:w-1/2 mx-auto mb-5">
                <table className="table-fixed w-full">
                    <thead>
                        <tr>
                            <th className="w-1/3 text-left px-2">
                                {language === 'mandarin' ? '主餐' : language === 'english' ? 'Main Dish' : 'メインディッシュ'}
                            </th>
                            <th className="w-1/3 text-left px-2">
                                {language === 'mandarin' ? '副餐' : language === 'english' ? 'Side Dish' : 'サイドディッシュ'}
                            </th>
                            <th className="w-1/3 text-left px-2">
                                {language === 'mandarin' ? '飲料' : language === 'english' ? 'Drink' : '飲み物'}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td className="border px-2 py-2">{quota.main}</td>
                        <td className="border px-2 py-2">{quota.side}</td>
                        <td className="border px-2 py-2">{quota.drink}</td>
                        </tr>
                    </tbody>
                    </table>
                </div>

                <div className='mx-auto max-w-screen-lg w-full md:w-1/2 px-4'>

                {!loading &&
                    menuOrder.map((key: string) => {
                        const translatedKey = menuOrderTranslations[key];
                        const groupedMenu = groupedMenuItems[key].map((menuItem: MenuItem) => {
                        return <MenuItemCard menuItem={menuItem} key={menuItem._id} language={language}/>;
                        });
                        return (
                        <Fragment key={key}>
                            <h1 className="text-3xl border-b-2 border-black">{
                                language === 'mandarin' ? translatedKey.mandarin : language === 'english' ? translatedKey.english : translatedKey.japanese
                            }</h1>
                            {groupedMenu}
                        </Fragment>
                        );
                    })}

                </div>
                <div ref={stickyDivRef} id="stickyDiv" className="flex items-end justify-center fixed bottom-0 w-full">
                    <button className="bg-blue-500 w-full hover:bg-blue-700 text-white font-bold py-2 px-4" onClick={() => {
                    setConfirm(true);
                    }}>
                    {language === 'mandarin' ? '檢查購物車' : language === 'english' ? 'Check shopping cart' : 'ショッピングカートを確認する'}
                    </button>
                </div>

            </div>
        )}
        {!confirm ? <></>: (
            <>
            <div className='flex flex-col p-5'>
                <div className="flex justify-center items-center ">
                    <table className="table-auto w-128">
                    <thead>
                        <tr>
                        <th className="px-4 py-2 text-left">名稱</th>
                        <th className="px-4 py-2 text-left">數量</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderRequest && orderRequest.orderDetail.map((item, index) => {
                        return (
                            <tr key={index}>
                            <td className="border px-4 py-2">
                                {language === 'mandarin' && item.name}
                                {language === 'english' && item.englishName}
                                {language === 'japanese' && item.japaneseName}
                            </td>
                            <td className="border px-4 py-2">{item.quantity}</td>
                            </tr>
                        );
                        })}
                    </tbody>
                    </table>
                </div>
                <div className="flex justify-around w-full text-2xl my-5">
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-1/2" onClick={handleSubmit}>
                        {language === 'mandarin' ? '確認送出訂單' : language === 'english' ? 'Confirm order' : '注文を確認する'}
                    </button>
                </div>
                <div className="flex justify-around w-full text-2xl my-5">
                        <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-1/2" onClick={()=>{
                        setConfirm(false)
                    }}>
                        {language === 'mandarin' ? '返回修改訂單' : language === 'english' ? 'Return to modify order' : '注文を変更する'}
                    </button>
                </div>
            </div>
        </>
        )}
        
        </OrderContext.Provider>

  );
}

export default RoomOrderView;

