import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { initialMenuItemFormData } from '../static/Static';
import { TextField, Select, MenuItem, Grid } from '@mui/material';
import '../App.css';
import axiosInstance from '../Axios/axios';
import Navbar from '../components/NavBar';
import axiosCredentialsInstance from '../Axios/axiosCredentials';
function MenuItemConfigView() {
    const { menuItemId } = useParams();
    const { restaurantId } = useParams();
    const Navigate = useNavigate();    
    const [formData, setFormData] = useState(initialMenuItemFormData)
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({
        name: "",
        englishName: false,
        japaneseName: false,
        price: false,
        type: false,
        description: false,
        photoUrl: "",
        note: false,
    })
    const handleChange = (e: any) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    
    useEffect(() => {
        if (menuItemId != null) {
            axiosInstance.get(`/restaurant/menu/${menuItemId}`)
                .then(data => {
                    setFormData(data.data);
                    setLoading(false);
                })
                .catch(error => {
                    console.error(error);
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [menuItemId]);
    
    return (
    <>
    <Navbar />

    <div className='w-full flex justify-center p-5'>
    <div className='w-128 flex flex-col mx-auto'>
    { loading ? <></> :
      <Grid container spacing={2}>
          <Grid item xs={12}>
              <TextField
              variant="outlined"
              fullWidth
              id="name"
              label="名稱"
              name="name"
              autoComplete="name"
              onChange={handleChange}
              defaultValue={formData.name}
              error={errors.name ? true : false}
              helperText={errors.name ? errors.name : null}
              />
          </Grid>
          <Grid item xs={6}>
              <TextField
              variant="outlined"
              fullWidth
              id="englishName"
              label="英文名稱"
              name="englishName"
              autoComplete="englishName"
              onChange={handleChange}
              defaultValue={formData.englishName}
              error={errors.englishName ? true : false}
              helperText={errors.englishName ? errors.englishName : null}
              />
          </Grid>
          <Grid item xs={6}>
              <TextField
              variant="outlined"
              fullWidth
              id="japaneseName"
              label="日文名稱"
              name="japaneseName"
              autoComplete="japaneseName"
              onChange={handleChange}
              defaultValue={formData.japaneseName}
              error={errors.japaneseName ? true : false}
              helperText={errors.japaneseName ? errors.japaneseName : null}
              />
          </Grid>

          <Grid item xs={12}>
              <TextField
              variant="outlined"
              fullWidth
              id="price"
              label="價格"
              name="price"
              autoComplete="price"
              onChange={handleChange}
              defaultValue={formData.price}
              error={errors.price ? true : false}
              helperText={errors.price ? errors.price : null}
              />
          </Grid>
          <Grid item xs={6}>

            <Select
            labelId="type"
            id="type"
            fullWidth
            label="類型"
            name="type"
            value={formData.type}
            onChange={handleChange}
            >
                <MenuItem value={"main"}>主食</MenuItem>
                <MenuItem value={"side"}>副餐</MenuItem>
                <MenuItem value={"drink"}>飲料</MenuItem>
            </Select>
            </Grid>
          <Grid item xs={6}>
              <TextField
              variant="outlined"
              fullWidth
              id="description"
              label="描述"
              name="description"
              autoComplete="description"
              onChange={handleChange}
              defaultValue={formData.description}
              error={errors.description ? true : false}
              helperText={errors.description ? errors.description : null}
            />
          </Grid>
          <Grid item xs={12}>
              <TextField
              variant="outlined"
              fullWidth
              id="photoUrl"
              label="照片網址"
              name="photoUrl"
              autoComplete="photoUrl"
              onChange={handleChange}
              defaultValue={formData.photoUrl}
              error={errors.photoUrl ? true : false}
              helperText={errors.photoUrl ? errors.photoUrl : null}
            />
          </Grid>
          <Grid item xs={12}>
              <TextField
              variant="outlined"
              fullWidth
              id="note"
              label="備註"
              name="note"
              autoComplete="note"
              onChange={handleChange}
              defaultValue={formData.note}
              error={errors.note ? true : false}
              helperText={errors.note ? errors.note : null}
            />
          </Grid>
            <Grid item xs={12}>
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full flex justify-center text-3xl align-middle w-full"
                    onClick={()=>{
                        if(restaurantId != undefined){
                            axiosCredentialsInstance.post(`/restaurant/${restaurantId}/menu/add`, JSON.stringify(formData)).then(() => {
                                window.location.reload();
                            })

                        }else if(menuItemId != undefined){
                            axiosCredentialsInstance.post(`/restaurant/menu/${menuItemId}/update`, JSON.stringify(formData)).then(() => {
                                Navigate(`/restaurant`)
                            })
                        }
                    }}>
                    {restaurantId != undefined ? "儲存並新增下一筆" : "儲存"}
                </button>
                {menuItemId != null &&
                    <button className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full flex justify-center text-3xl align-middle w-full my-3' onClick={()=>{
                        axiosCredentialsInstance.delete(`/restaurant/menu/${menuItemId}`).then(() => {
                            Navigate(`/restaurant`)
                        })
                    }}>
                        刪除
                    </button>
                }
                <button className='bg-green-200 hover:bg-green-300 text-white font-bold py-2 px-4 rounded-full flex justify-center text-3xl align-middle w-full my-3' onClick={()=>{
                    Navigate(`/restaurant`)
                    }}>
                        回上一頁
                </button>
            </Grid>
        </Grid>
    }
    </div>
    </div>
    </>
  );
}

export default MenuItemConfigView;
