import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { initialRestaurantFormData } from '../static/Static';
import { TextField, Grid } from '@mui/material';
import '../App.css';
import axiosInstance from '../Axios/axios';
import Navbar from '../components/NavBar';
import axiosCredentialsInstance from '../Axios/axiosCredentials';
function RestaurantConfigView() {
    const { restaurantId } = useParams();
    const Navigate = useNavigate();    
    const [formData, setFormData] = useState(initialRestaurantFormData)
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({
        name: "",
        address: false,
        phone: false,
        note: false,
        drink: false,
    })
    const handleChange = (e: any) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    useEffect(() => {
        if (restaurantId != null) {
            axiosInstance.get(`/restaurant/${restaurantId}`)
                .then(data => {
                    delete data.data.menuItems;
                    setFormData(data.data);
                    setLoading(false);
                })
                .catch(error => {
                    console.error(error);
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [restaurantId]);
    
    return (
    <>
    <Navbar />
    <div className='w-full flex justify-center p-5'>
    <div className='w-128 flex flex-col mx-auto'>
    { loading ? <></> :
      <Grid container spacing={2}>
          <Grid item xs={12}>
              <TextField
              variant="outlined"
              fullWidth
              id="name"
              label="餐廳名稱"
              name="name"
              autoComplete="name"
              onChange={handleChange}
              defaultValue={formData.name}
              error={errors.name ? true : false}
              helperText={errors.name ? errors.name : null}
              />
          </Grid>
          <Grid item xs={12}>
              <TextField
              variant="outlined"
              fullWidth
              id="address"
              label="地址"
              name="address"
              autoComplete="address"
              onChange={handleChange}
              defaultValue={formData.address}
              error={errors.address ? true : false}
              helperText={errors.address ? errors.address : null}
              />
          </Grid>
          <Grid item xs={6}>
              <TextField
              variant="outlined"
              fullWidth
              id="phone"
              label="電話"
              name="phone"
              autoComplete="phone"
              onChange={handleChange}
              defaultValue={formData.phone}
              error={errors.phone ? true : false}
              helperText={errors.phone ? errors.phone : null}
            />
          </Grid>
          <Grid item xs={6}>
              <TextField
              variant="outlined"
              fullWidth
              id="note"
              label="備註"
              name="note"
              autoComplete="note"
              onChange={handleChange}
              defaultValue={formData.note}
              error={errors.note ? true : false}
              helperText={errors.note ? errors.note : null}
            />
          </Grid>
            <Grid item xs={12}>
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full flex justify-center text-3xl align-middle w-full"
                    onClick={()=>{
                        axiosCredentialsInstance.post(restaurantId != null ? `/restaurant/${restaurantId}/update` : `/restaurant/add`, JSON.stringify(formData)).then((res)=>{
                            if (res.status === 200) {
                                Navigate(`/restaurant`)
                            } else {
                                setErrors(res.data)
                            }
                        }).catch((err)=>{
                        })
                
                    }}>
                    儲存並繼續
                </button>
                <button className='bg-green-200 hover:bg-green-300 font-bold py-2 px-4 rounded-full flex justify-center text-3xl align-middle w-full my-3 text-black' onClick={()=>{
                    Navigate(`/restaurant`)
                    }}>
                        回上一頁
                </button>
            </Grid>
        </Grid>
    }
    </div>
    </div>
    </>

  );
}

export default RestaurantConfigView;
