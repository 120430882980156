import { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Restaurant } from '../interfaces';
import RestaurantCard from '../components/RestaurantCard';
import Navbar from '../components/NavBar';
import '../App.css';
import axiosCredentialsInstance from '../Axios/axiosCredentials';
import Loading from '../components/Loading';

function RestaurantView() {
    const Navigate = useNavigate()
    const [restaurants, setRestaurants] = useState([] as Restaurant[])
    const [loading, setLoading] = useState(true as boolean)
    useEffect(()=>{
        axiosCredentialsInstance.get('/restaurant').then(data => {
            setRestaurants(data.data)
            setLoading(false)
        })
    },[])
    return (
        <>
        <Navbar />
        { loading ? <Loading/> :
        (<div className='w-full flex flex-col justify-center mt-10'>
            <div className='mx-auto'>
                {restaurants && restaurants.length > 0 && (
                    <>
                        {restaurants.map((restaurant: Restaurant, index: number) => {
                            return(
                            <RestaurantCard restaurant={restaurant} key={index}/>
                            )
                        })}
                    </>
                )}
                
                <div className='w-96 md:w-128 lg:w-220 m-3'>
                    <div className='flex justify-between rounded-md'>
                        <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full' onClick={()=>{
                            Navigate(`/restaurant/add`)
                        }}>新增早餐店</button> 
                    </div>
                </div>
            </div>
        </div>
        )}
        </>
  );
}

export default RestaurantView;