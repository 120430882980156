import { useState, useEffect } from 'react';
import axiosCredentialsInstance from '../Axios/axiosCredentials';
import { RoomCode } from '../interfaces';
import Navbar from '../components/NavBar';
import {QRCodeSVG} from 'qrcode.react';

import { Select, MenuItem } from '@mui/material';
import '../App.css';

function RoomOrderView() {
  const [rooms, setRooms] = useState([] as any[]);
  const [selectedRooms, setSelectedRooms] = useState([] as any[]);
  const [roomCode, setRoomCode] = useState({} as RoomCode[]);
  const [note, setNote] = useState('');

  useEffect(() => {
    axiosCredentialsInstance.get('/room/activated-rooms').then((res) => {
      setRooms(res.data);
      setSelectedRooms(res.data);
    });
  }, []);

  const handleChange = (e: any) => {
    if (e.target.value === 'all') {
      setSelectedRooms(rooms);
      return;
    }
    setSelectedRooms(rooms.filter((room: any) => room._id === e.target.value));
  };

  const handlePrint = () => {
    const printContents = document.getElementById('print-section')?.innerHTML;
    const originalContents = document.body.innerHTML;

    if (printContents) {
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    }
  };

  
  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);
  };
  return (
    <>
      <Navbar />
      <div className="w-full flex flex-col justify-center p-5">
        <div className="p-8">
          <div className="flex justify-around w-full text-2xl mb-10 non-print-section">
            <div className="w-1/2">
              <Select
                labelId="type"
                id="type"
                fullWidth
                label="類型"
                name="type"
                onChange={handleChange}
                defaultValue="all"
              >
                <MenuItem value="all">全部</MenuItem>
                {rooms.map((room: any, index: any) => {
                  return (
                    <MenuItem value={room._id} key={index}>
                      {room.roomNumber}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </div>
          <div className="flex justify-around w-full text-2xl mb-10 non-print-section">
            <div className="w-1/2">
              <input type="text" value={note} onChange={handleNoteChange} className='shadow-lg w-full h-24 bg-gray-200' />
            </div>
          </div>


          <div className="flex justify-around w-full text-2xl non-print-section">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-1/2"
              onClick={() => {
                const generateQRCode = async () => {
                  const data = await selectedRooms.reduce(async (acc: any, cur: any) => {
                    var accessToken, expireAt;
                    await axiosCredentialsInstance
                      .post(`/auth/${cur._id}/sign`)
                      .then((res) => {
                        accessToken = res.data.accessToken;
                        expireAt = res.data.expireAt;
                      });
                    const accumulatedData = await acc;
                    return [
                      ...accumulatedData,
                      {
                        _id: cur._id,
                        roomNumber: cur.roomNumber,
                        accessToken: accessToken,
                        expireAt: expireAt,
                      },
                    ];
                  }, []);
                  setRoomCode(data);
                };
                generateQRCode();
              }}
            >
              產生QRCODE
            </button>
          </div>

          <div className="print-section">
            <div className="flex flex-wrap w-full my-5 justify-center">
              {roomCode &&
                roomCode.length > 0 &&
                roomCode.map((room: RoomCode, index: number) => {
                  const url = `https://guest.fulwon-group.com/order/${room._id}?token=${room.accessToken}`;
                  return (
                    <div className="flex flex-wrap my-5 mx-5 shadow-md w-72 min-h-80 print-canvas outline-dotted" key={index}>
                      <div className="w-full flex justify-center text-3xl my-3">{room.roomNumber}</div>
                      <div className="w-full flex justify-center">
                        <QRCodeSVG value={url} />
                      </div>
                      <div className="w-full flex justify-center my-2 overflow-auto break-all px-4">{note}</div>

                      <div className="w-full flex justify-center my-2">Valid before {room.expireAt}</div>

                    </div>
                  );
                })}
            </div>
          </div>

          <div className="flex justify-around w-full text-2xl my-10 non-print-section">
            <button
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-1/2"
              onClick={() => {
                window.print();
              }}
            >
              列印
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default RoomOrderView;
