import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Order } from '../interfaces';
import axiosInstance from '../Axios/axios';
import { Transition }  from '@headlessui/react';
import { Switch } from '@mui/material';
import Alert from './Alert';
import '../App.css';
import axiosCredentialsInstance from '../Axios/axiosCredentials';



function RoomCard(props: any) {
    const [showDropDown, setShowDropDown] = useState(false)
    const [orderDetail, setOrderDetail] = useState({} as Order)
    const [ showAlert, setShowAlert ] = useState(false)
    const [status, setStatus] = useState(props.room.status)
    const [showDeleteOrderPopUp, setShowDeleteOrderPopUp] = useState(false)
    const Navigate = useNavigate();
    var total = 0

    const handleChange = (e: any) => {
        axiosCredentialsInstance.post(`/room/${props.room._id}/switch-activate`).then(res => {
        }).catch(err => {
            window.location.reload()
        })
        // axiosCredentialsInstance.post(`/room/${props.room._id}/update`, {activate: e.target.checked})
    }
    return (
        <div className='w-96 md:w-128 lg:w-220 m-3'>
        <Alert show={showAlert} setShowAlert={setShowAlert} message={"房間尚未啟用"}/>
        <div className='bg-orange-200 hover:bg-orange-300 p-3 flex justify-between rounded-md' onClick={()=>{
            if (orderDetail && Object.keys(orderDetail).length === 0){
                axiosCredentialsInstance.get(`/order/${props.room._id}`).then(order => {
                    setOrderDetail(order.data)
                })
            }
            setShowDropDown(!showDropDown)
        }}>
            <div className='flex items-center min-h-full'>
                <p className='mx-2'>
                    房號:{props.room.roomNumber} 
                </p>
                <p className='mx-2'>
                    房型:{props.room.roomType}
                </p>
                <p className='mx-2'>
                    主餐:{props.room.quantity.main}
                </p>
                <p className='mx-2'>
                    副餐:{props.room.quantity.side}
                </p>
                <p className='mx-2'>
                    飲料:{props.room.quantity.drink}
                </p>
            </div>
            <div className='flex items-center h-full'>
                <p className='mx-2'>
                    狀況：{status}
                </p>
                <p className='mx-2' onClick={(e) => e.stopPropagation()}>
                <Switch onChange={handleChange} defaultChecked={props.room.activate ? true : false} />
                </p>
            </div>
        </div>

        <div className={`overflow-hidden transition-all duration-500 ${showDropDown ? 'h-auto' : 'h-0 max-h-0'}`}>
            <div className='p-3 border-2 rounded-md flex flex-wrap'>
                <div className="flex flex-col w-full md:w-4/5 lg:w-2/3 mx-auto">
                    <h1 className='text-xl font-bold'>訂單明細</h1>
                    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 mx-auto">
                        <div className="inline-block min-w-full py-2 sm:px-4 lg:px-6">
                        <div className="overflow-hidden">
                            <table className="w-80 md:w-96 lg:w-128 min-w-full text-left text-sm font-light">
                            <thead className="border-b font-medium dark:border-neutral-500">
                                <tr>
                                <th scope="col" className="px-4 py-3">品項</th>
                                <th scope="col" className="px-4 py-3">數量</th>
                                <th scope="col" className="px-4 py-3">單價</th>
                                <th scope="col" className="px-4 py-3">小計</th>
                                </tr>
                            </thead>
                            <tbody>
                            {orderDetail && orderDetail.orderItems ? orderDetail.orderItems.map((orderItem, index) => {
                                total += orderItem.quantity * orderItem.price
                                return (
                                    <tr className="border-b dark:border-neutral-500" key={index}>
                                    <td className="whitespace-nowrap px-4 py-3 font-medium">{orderItem.name}</td>
                                    <td className="whitespace-nowrap px-4 py-3">{orderItem.quantity}</td>
                                    <td className="whitespace-nowrap px-4 py-3">{orderItem.price}</td>
                                    <td className="whitespace-nowrap px-4 py-3">{orderItem.quantity * orderItem.price}</td>
                                    </tr>
                                )
                            }) : ''}
                                <tr className="border-b dark:border-neutral-500">
                                    <td className="whitespace-nowrap px-4 py-3 font-medium">總價</td>
                                    <td className="whitespace-nowrap px-4 py-3"></td>
                                    <td className="whitespace-nowrap px-4 py-3"></td>
                                    <td className="whitespace-nowrap px-4 py-3">{total}</td>
                                </tr>
                            </tbody>
                            </table>
                            <div className='m-3'>備註：{orderDetail && orderDetail.note}</div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col w-full lg:w-1/3 py-5 lg:pl-10 mx-auto'>
                    <button className='bg-green-200 hover:bg-green-300 p-3 m-2 rounded-md' onClick={()=>{
                        axiosCredentialsInstance.get(`/room/${props.room._id}/activate-status`).then(data => {
                            if(data.data === false){
                                setShowAlert(true)
                            }
                            else{
                                Navigate(`/admin-order/${props.room._id}`)
                            }
                        })
                            
                    }}>
                            修改訂單
                    </button>
                    
                    <button className='bg-green-200 hover:bg-green-300 p-3 m-2 rounded-md' onClick={()=>{
                        Navigate(`/edit-room/${props.room._id}`)
                    }}>
                            修改房型
                    </button>
                    <Transition
                        show={showDeleteOrderPopUp}
                        enter="transition-opacity duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div
                        className="fixed top-0 left-0 w-full h-full z-50 flex items-center justify-center"
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                        >
                        <div className="bg-white rounded-lg p-8">
                                <div className="flex justify-around w-full">
                                    <button
                                    className="bg-red-300 hover:bg-red-400 text-white font-bold py-2 px-4 m-2 rounded-md flex justify-center text-3xl align-middle w-full"
                                    onClick={()=>{
                                        axiosInstance.delete(`/order/${orderDetail._id}/${props.room._id}`)
                                        setStatus('未送單')
                                        setOrderDetail({} as Order)
                                        setShowDeleteOrderPopUp(false)
                                    }}
                                    >
                                    刪除
                                    </button>
                                    <button className='bg-green-300 hover:bg-green-400 text-white font-bold py-2 px-4 m-2 rounded-md flex justify-center text-3xl align-middle w-full'
                                    onClick={()=>{
                                        setShowDeleteOrderPopUp(false)
                                    }}>
                                    取消
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition>
                    { orderDetail &&
                        <button className='bg-red-200 hover:bg-red-300 p-3 m-2 rounded-md' onClick={()=>{setShowDeleteOrderPopUp(true)}}>
                                刪除訂單
                        </button>
                    }
                </div>
                </div>
            </div>
        </div>
  );
}

export default RoomCard;
