import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { initialRoomFormData } from '../static/Static';
import { TextField, Grid } from '@mui/material';
import Navbar from '../components/NavBar';
import '../App.css';
import axiosCredentialsInstance from '../Axios/axiosCredentials';

function RoomView() {
    const { id } = useParams();
    const [formData, setFormData] = useState(initialRoomFormData)
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({
        roomNumber: "",
        roomType: false,
        main: false,
        side: false,
        drink: false,
        note: false
    })
    const handleChange = (e: any) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const Navigate = useNavigate();


    const validateQuantity = (name: string, value: string) => {
        const regex = /^\d{1,3}$/;
        const isValid = regex.test(value);
        setErrors((errors) => ({ ...errors, [name]: isValid ? false : true }));
      };


    const handleQuantityChange = (e: any) => {
        const { name, value } = e.target;
        validateQuantity(name, value)
        setFormData({
            ...formData,
            quantity: {
                ...formData.quantity,
                [name]: value ? parseInt(value): 0
            }
        })
    }

    useEffect(()=>{
        if(id == null){
            setLoading(false)
            return
        }
        axiosCredentialsInstance.get(`/room/${id}`).then(data => {
            setFormData(
                {
                    roomNumber: data.data.roomNumber ? data.data.roomNumber : '',
                    roomType: data.data.roomType ? data.data.roomType : '',
                    quantity: data.data.quantity ? data.data.quantity : {"main": 0, "side": 0, "drink": 0},
                    note: data.data.note ? data.data.note : ''
                }
            )
            setLoading(false)
        })
    }
    ,[])


    return (
    <>
    <Navbar />
    <div className='w-full flex justify-center p-5'>
    <div className='w-128 flex flex-col mx-auto'>
    { loading ? <></> :
      <Grid container spacing={2}>
          <Grid item xs={12}>
              <TextField
              variant="outlined"
              fullWidth
              id="roomNumber"
              label="房間號碼"
              name="roomNumber"
              autoComplete="roomNumber"
              onChange={handleChange}
              defaultValue={formData.roomNumber}
              error={errors.roomNumber ? true : false}
              helperText={errors.roomNumber ? errors.roomNumber : null}
              />
          </Grid>
          <Grid item xs={12}>
              <TextField
              variant="outlined"
              fullWidth
              id="roomType"
              label="房型"
              name="roomType"
              autoComplete="roomType"
              onChange={handleChange}
              defaultValue={formData.roomType}
              error={errors.roomType ? true : false}
              helperText={errors.roomType ? errors.roomType : null}
              />
          </Grid>
          <Grid item xs={4}>
              <TextField
              variant="outlined"
              fullWidth
              id="main"
              label="主餐數"
              name="main"
              autoComplete="main"
              onChange={handleQuantityChange}
              defaultValue={formData.quantity.main}
              error={errors.main ? true : false}
              helperText={errors.main ? errors.main : null}
            />
          </Grid>
          <Grid item xs={4}>
              <TextField
              variant="outlined"
              fullWidth
              id="side"
              label="副餐數"
              name="side"
              autoComplete="side"
              onChange={handleQuantityChange}
              defaultValue={formData.quantity.side}
              error={errors.side ? true : false}
              helperText={errors.side ? errors.side : null}
            />
          </Grid>
          <Grid item xs={4}>
              <TextField
              variant="outlined"
              fullWidth
              id="drink"
              label="飲料數"
              name="drink"
              autoComplete="drink"
              onChange={handleQuantityChange}
              defaultValue={formData.quantity.drink}
              error={errors.drink ? true : false}
              helperText={errors.drink ? errors.drink : null}
            />
          </Grid>

            <Grid item xs={12}>
                <TextField
                variant="outlined"
                fullWidth
                id="note"
                label="備註"
                name="note"
                autoComplete="note"
                onChange={handleQuantityChange}
                defaultValue={formData.note}
                error={errors.note ? true : false}
                helperText={errors.note ? errors.note : null}
                />
            </Grid>
            
            <Grid item xs={12}>
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full flex justify-center text-3xl align-middle w-full"
                    onClick={()=>{
                        if(id == null){
                            axiosCredentialsInstance.post('/room/add', JSON.stringify({...formData, status:"未送單", activate:false})).then(() => {
                                Navigate(`/`)
                                window.location.reload();
                            })

                        }
                        else{
                            axiosCredentialsInstance.post(`/room/${id}/update`, JSON.stringify(formData)).then(() => {
                                Navigate(`/`)
                                window.location.reload();
                            })
                    }
                    }}
                    >
                    儲存並繼續
                </button>
            </Grid>

            {id &&
            <Grid item xs={12}>
                <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full flex justify-center text-3xl align-middle w-full"
                    onClick={()=>{
                        axiosCredentialsInstance.delete(`/room/${id}`).then( () => {
                            Navigate(`/`)
                            window.location.reload();
                        })
                    }}>
                    刪除
                </button>
            </Grid>
            }


        </Grid>}
    </div>
    </div>
    </>
  );
}

export default RoomView;
