import { useState, useEffect } from 'react';
import { OrderSummary } from '../interfaces';
import axiosCredentialsInstance from '../Axios/axiosCredentials';
import Navbar from '../components/NavBar';
import { Transition }  from '@headlessui/react';
import Loading from '../components/Loading';
import { useNavigate } from 'react-router-dom';
import DatePicker from '../components/DatePicker';


function OrderSummaryView() {
    const [ orderSummary, setOrderSummary ] = useState([] as OrderSummary[]);
    const [ activatedRestaurant, setActivatedRestaurant ] = useState("");
    const [ loading, setLoading ] = useState(true)
    const [ showConfirmPopUp, setshowConfirmPopUp ] = useState(false)
    const [ showDeleteAdminOrderPopUp, setShowDeleteAdminOrderPopUp ] = useState(false)
    const Navigate = useNavigate()
    useEffect(() => {
        axiosCredentialsInstance.get('/restaurant/activated').then(data => {
            setActivatedRestaurant(data.data[0].name);
        }).catch(err => {
            console.log(err)
        })

        axiosCredentialsInstance.get('/order').then(orderData => {
            axiosCredentialsInstance.get('/order/admin/order').then(adminOrderData => {
                const combinedData = [...orderData.data, ...adminOrderData.data];
                setOrderSummary(combinedData);
                setLoading(false);
            }).catch(err => {
            });
        }).catch(err => {
        });
    }, []);
    

    return (
        <>
            <Navbar/>
            { loading ? <Loading/> : 
            (<div className='flex flex-col p-5'>
                <div className="flex justify-around items-center my-5">
                    <h1 className='text-2xl text-center p-2 bg-slate-100 shadow-lg' style={{ minWidth: '12rem' }}>
                        {activatedRestaurant}
                    </h1>
                    <DatePicker />
                </div>
            
                <div className="flex justify-center items-center flex-wrap" onClick={()=>{
                    console.log(activatedRestaurant)
                }}>
                    <table className="table-auto w-220">
                        <thead>
                        <tr>
                            <th className="px-4 py-2 text-left w-2/3">名稱</th>
                            <th className="px-4 py-2 text-left">價格</th>
                            <th className="px-4 py-2 text-left">房間</th>
                        </tr>
                        </thead>
                        <tbody>
                        {!loading &&
                            orderSummary &&
                            orderSummary.map((orderSummary: OrderSummary, index) => {
                            const totalPrice = orderSummary.orderItems.reduce(
                                (acc, item) => acc + item.price * item.quantity,
                                0
                            );
                            return (
                                <tr key={index}>
                                <td className="border px-4 py-2">
                                    {orderSummary.orderItems.map((item, index) => (
                                    <span key={index}>
                                        {item.name} x {item.quantity}
                                        {index !== orderSummary.orderItems.length - 1 && ','}&nbsp;
                                    </span>
                                    ))}
                                </td>
                                <td className="border px-4 py-2">{totalPrice}</td>
                                <td className="border px-4 py-2">{orderSummary.roomNumber}</td>
                                </tr>
                            );
                            })}
                        </tbody>
                    </table>
                    {!loading && orderSummary && (
                        <p className="mt-4 text-center text-ivory text-lg font-semibold w-full">
                        總價格：{orderSummary.reduce(
                            (acc, order) =>
                            acc +
                            order.orderItems.reduce((acc2, item) => acc2 + item.price * item.quantity, 0),
                            0
                        )}
                        </p>
                    )}
                    </div>
                <div className="flex justify-center items-center my-10">
                <Transition
                        show={showDeleteAdminOrderPopUp}
                        enter="transition-opacity duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div
                        className="fixed top-0 left-0 w-full h-full z-50 flex items-center justify-center"
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                        >
                        <div className="bg-white rounded-lg p-8">
                            <div className='flex justify-center w-full text-2xl'>刪除所有單點？</div>
                                <div className="flex justify-around w-full">
                                    <button
                                    className="bg-red-300 hover:bg-red-400 text-white font-bold py-2 px-4 m-2 rounded-md flex justify-center text-3xl align-middle w-full"
                                    onClick={()=>{
                                        axiosCredentialsInstance.delete('/order/admin/order/delete').then(data => {
                                            setshowConfirmPopUp(false)
                                            window.location.reload()
                                        }).catch(err => {
                                        })
                                    }}
                                    >
                                    刪除
                                    </button>
                                    <button className='bg-green-300 hover:bg-green-400 text-white font-bold py-2 px-4 m-2 rounded-md flex justify-center text-3xl align-middle w-full'
                                    onClick={()=>{
                                        setShowDeleteAdminOrderPopUp(false)
                                    }}>
                                    取消
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition>
                <Transition
                        show={showConfirmPopUp}
                        enter="transition-opacity duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div
                        className="fixed top-0 left-0 w-full h-full z-50 flex items-center justify-center"
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                        >
                        <div className="bg-white rounded-lg p-8">
                            <div className='flex justify-center w-full text-2xl'>確認要完成今日訂單嗎，確認後會刪除今日所有訂單</div>
                                <div className="flex justify-around w-full">
                                    <button
                                    className="bg-red-300 hover:bg-red-400 text-white font-bold py-2 px-4 m-2 rounded-md flex justify-center text-3xl align-middle w-full"
                                    onClick={()=>{
                                        axiosCredentialsInstance.delete('/order/delete-all').then(data => {
                                            setshowConfirmPopUp(false)
                                            window.location.reload()
                                        }).catch(err => {
                                        })
                                    }}
                                    >
                                    刪除
                                    </button>
                                    <button className='bg-green-300 hover:bg-green-400 text-white font-bold py-2 px-4 m-2 rounded-md flex justify-center text-3xl align-middle w-full'
                                    onClick={()=>{
                                        setshowConfirmPopUp(false)
                                    }}>
                                    取消
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition>



                    <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' onClick={()=>{
                        window.print()
                    }}>列印</button>
                    <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-10' onClick={()=>{
                        window.location.reload()
                    }}>重新整理</button>
                    <button className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded' onClick={()=>{
                        setshowConfirmPopUp(true)
                    }}>完成今日訂單</button>
                    <button className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mx-10' onClick={()=>{
                        Navigate('/admin/order')
                    }}>新增單點</button>
                    <button className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded' onClick={()=>{
                        setShowDeleteAdminOrderPopUp(true)
                    }}>刪除單點</button>               
                </div>
            </div>
            )}
        </>
  );
}

export default OrderSummaryView;

