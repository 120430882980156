import React, { useState } from 'react';

const DateTimeInput: React.FC = () => {
  const [selectedDateTime, setSelectedDateTime] = useState<string>('');

  const handleDateTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDateTime(event.target.value);
  };

  return (
    <div className="flex flex-col items-center space-y-4">
      <input
        type="datetime-local"
        value={selectedDateTime}
        onChange={handleDateTimeChange}
        className="border border-gray-300 rounded px-4 py-2"
      />
    </div>
  );
};

export default DateTimeInput;
