import { BrowserRouter, Route, Routes } from "react-router-dom";
import DashBoard from "./pages/DashBoard";
import RoomView from "./pages/RoomView";
import LoginView from "./pages/LoginView";
import ProtectedRoute from "./pages/ProtectedRoute";
import JwtProtectedRoute from "./pages/JwtProtectedRoute";
import OrderView from "./pages/OrderView";
import OrderInfoView from "./pages/OrderInfoView";
import OrderSummaryView from "./pages/OrderSummaryView";
import RoomOrderView from "./pages/RoomOrderView";
import RoomOrderInfoView from "./pages/RoomOrderInfoView";
import RoomScanningView from "./pages/RoomScanningView";
import RestaurantView from "./pages/RestaurantView";
import RestaurantConfigView from "./pages/RestaurantConfigView";
import MenuItemConfigView from "./pages/MenuItemConfig";
import ErrorView from "./pages/ErrorView";
import GuestErrorView from "./pages/GuestErrorView";
import Footer from "./components/Footer";
import AdminOrderView from "./pages/AdminOrder";

import './App.css';


function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <BrowserRouter>
      <div className="flex-grow">
        <Routes>
          <Route path='/' element={<ProtectedRoute authenticationPath={'/login'} outlet={<DashBoard />} />} />
          <Route path="/login" element={<LoginView />} />
          <Route path="/room/add" element={<ProtectedRoute authenticationPath={'/login'} outlet={<RoomView />} />} />
          <Route path="/edit-room/:id" element={<ProtectedRoute authenticationPath={'/login'} outlet={<RoomView />} />} />
          <Route path="/admin-room-order-info/:roomId" element={<ProtectedRoute authenticationPath={'/error'} outlet={<OrderInfoView />} />} />
          <Route path="/admin/order" element={<ProtectedRoute authenticationPath={'/error'} outlet={<AdminOrderView />} />} />
          <Route path="/admin-order/:roomId" element={<ProtectedRoute authenticationPath={'/error'} outlet={<OrderView />} />} />
          <Route path="/restaurant" element={<ProtectedRoute authenticationPath={'/login'} outlet={<RestaurantView />} />} />
          <Route path="/restaurant/add" element={<ProtectedRoute authenticationPath={'/login'} outlet={<RestaurantConfigView />} />} />
          <Route path="/restaurant/:restaurantId/edit" element={<ProtectedRoute authenticationPath={'/login'} outlet={<RestaurantConfigView />} />} />
          <Route path="/restaurant/:restaurantId/menu-item/add" element={<ProtectedRoute authenticationPath={'/login'} outlet={<MenuItemConfigView />} />} />
          <Route path="/restaurant/:menuItemId/menu-item/edit" element={<ProtectedRoute authenticationPath={'/login'} outlet={<MenuItemConfigView />} />} />
          <Route path="/order-summary" element={<ProtectedRoute authenticationPath={'/error'} outlet={<OrderSummaryView />} />} />
          <Route path="/room-qrcode" element={<ProtectedRoute authenticationPath={'/error'} outlet={<RoomScanningView />} />} />
          <Route path="/room-order-info/:roomId" element={<JwtProtectedRoute authenticationPath={'/guest-error'} outlet={<RoomOrderInfoView />} />} />
          <Route path="/order/:roomId" element={<JwtProtectedRoute authenticationPath={'/guest-error'} outlet={<RoomOrderView />} />} />
          <Route path="/error" element={<ErrorView message={"很抱歉，好像有什麼問題。"}/>} />
          <Route path="/guest-error" element={<GuestErrorView message={"很抱歉，好像有什麼問題。"}/>} />
        </Routes>
        </div>
        </BrowserRouter>
        <Footer />     
        </div>
  );
}

export default App;
