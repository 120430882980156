import { Transition } from '@headlessui/react';

function Alert(props: any) {
  return (
    <Transition
        show={props.show}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
    >
        <div
        className="fixed top-0 left-0 w-full h-full z-50 flex items-center justify-center"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
        <div className="bg-white rounded-lg p-8">
            <div className="flex justify-around w-full text-2xl">
                {props.message}
            </div>
            <div className="flex justify-around w-full">
                <button className='bg-green-300 hover:bg-green-400 text-white font-bold py-2 px-4 m-2 rounded-md flex justify-center text-3xl align-middle w-full'
                onClick={()=>{
                    props.setShowAlert(false)
                }}>
                了解
                </button>
            </div>
            </div>
        </div>
    </Transition>
  );
};

export default Alert;
