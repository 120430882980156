import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Order } from '../interfaces';
import Navbar from '../components/NavBar';
import axiosCredentialsInstance from '../Axios/axiosCredentials';
import Loading from '../components/Loading';
function OrderInfoView() {
    const Navigate = useNavigate();
    const [ orderDetail, setOrderDetail ] = useState({} as Order)
    const [ loading, setLoading ] = useState(true)
    const [ roomNumber, setRoomNumber ] = useState()
    const { roomId } = useParams();
    
    useEffect(()=>{
        axiosCredentialsInstance.get(`/order/${roomId}`).then(data => {
            setOrderDetail(data.data)
            setLoading(false)
        })
        axiosCredentialsInstance.get(`/room/${roomId}`).then(data => {
            setRoomNumber(data.data.roomNumber)
        })
    },[])
    return (
        <>
        <Navbar/>
        <div className='flex flex-col p-5'>
            { loading ? <Loading /> : (
            <>
            <h1 className='flex justify-center text-3xl my-10'>Hello {roomNumber}</h1>
            <div className="flex justify-center items-center ">
            <table className="table-auto w-128">
            <thead>
                <tr>
                <th className="px-4 py-2 text-left">名稱</th>
                <th className="px-4 py-2 text-left">數量</th>
                </tr>
            </thead>
            <tbody>
                {!loading && orderDetail && orderDetail.orderItems.map((item, index) => {
                return (
                    <tr key={index}>
                    <td className="border px-4 py-2">{item.name}</td>
                    <td className="border px-4 py-2">{item.quantity}</td>
                    </tr>
                );
                })}
            </tbody>
            </table>
        </div>
        <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-10' onClick={()=>{
            Navigate(`/admin-order/${roomId}`)
        }}>修改訂單</button>
        </>)}
      </div>
      </>
  );
}

export default OrderInfoView;

