import { Link } from "react-router-dom";
import '../App.css'
function Navbar(props: any) {

return (
    <nav className='h-16 mx-auto p-6 z-50 bg-white flex  shadow-md non-print-section'>
        <div className="flex items-center w-full xl:w-220">
            <Link to='/'>
                <div className="pt-2 mx-5">
                    總覽
                </div>
            </Link>
            <Link to='/order-summary'>
            <div className="pt-2 mx-5">
                訂單
            </div>
            </Link>
            <Link to='/restaurant'>
            <div className="pt-2 mx-5">
                餐廳
            </div>
            </Link>
            <Link to='/room-qrcode'>
            <div className="pt-2 mx-5">
                條碼
            </div>
            </Link>
        </div>
    </nav>
  );
}

export default Navbar;
