import { useState } from "react";
import { Navigate } from "react-router-dom";
import { ProtectedRouteProps } from "../interfaces";
import Loading from "../components/Loading";
import axiosInstance from "../Axios/axios";
import axiosCredentialsInstance from "../Axios/axiosCredentials";
export default function ProtectedRoute({ authenticationPath, outlet}: ProtectedRouteProps) {
    const [authentication, setAuthentication] = useState(false as boolean);
    const [loading, setLoading] = useState(true as boolean);
    axiosCredentialsInstance.get('/session/authenticate').then(data =>{
        if (data.status === 200){
            setAuthentication(true)
            setLoading(false)
        } else{
            setAuthentication(false)
            setLoading(false)
        }
    }).catch(err => {
        setAuthentication(false)
        setLoading(false)
    })
    if (loading) {
        return <Loading />;
      } else if (authentication) {
        return outlet;
      } else {
        return <Navigate to={{ pathname: authenticationPath }} />;
      }
  };